.container {

  .slide {
    &::-webkit-scrollbar{
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }

}