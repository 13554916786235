.container {

  form {

    width: 350px;

    button[type=submit] {
      background: var(--hover, linear-gradient(220deg, #615B99 0%, #594F74 52.91%, #372E43 100%));
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .sep {
      background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
    }

  }

}