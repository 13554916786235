.container {

  .top-bg {
    @apply bg-no-repeat bg-top bg-cover;
    background-image: url(../../../../assets/images/page-experts-section-overview/banner-background.png);
  }

  .insight {
    @apply bg-no-repeat bg-top bg-cover bg-white;
    background-image: url(../../../../assets/images/page-experts-section-overview/insight-background.png);
  }

}