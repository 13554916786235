.container {

  input[type=text],
  input[type=email],
  input[type=tel],
  textarea {
    @apply p-4 border border-primary-200 rounded-lg outline-none leading-[1.22] resize-none;

    &:focus, &:not(:placeholder-shown) {
      @apply border-primary-400;
    }

    &.error {
      @apply border-error placeholder-error text-error;
    }
  }

  hr {
    @apply w-[100px] h-px border-0;
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

}

.footer {

  button {
   background: linear-gradient(255deg, #615B99 2.24%, #594F74 60.08%, #372E43 111.56%);
  }

}