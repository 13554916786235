.container {

  max-height: 480px;

  .title {

    line-height: 1.1;

  }

  .description {
    @apply w-full;
    max-width: 632px;
  }

}
