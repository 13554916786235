.container {

  hr {
    @apply border-0 h-px;

    &.light {
      @apply bg-center bg-no-repeat bg-expanded;
      height: 2px;
      background-image: url(../../../../assets/images/common-tab-list/divider-mobile.png);
      @screen sm {
        background-image: url(../../../../assets/images/common-tab-list/divider.png);
      }
    }
  }

  .selected {

    &::after {
      content: "";
      @apply absolute -bottom-1 left-0 w-full h-1 bg-legacy-purple;
    }

  }

  .scroll {
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }

}