.container {

  @apply pointer-events-none;
  * {
    @apply pointer-events-none;
  }

  &.visible {
    @apply pointer-events-auto;
    * {
      @apply pointer-events-auto;
    }
  }

}