.container {

  @apply bg-repeat bg-top;
  background: linear-gradient(124deg, #EBEAF0 26.26%, #FFF 99.21%);
  background-size: 100% auto;

  @screen sm {
    background-image: url(../../../../assets/images/page-solution-layout-solution-approach/background.png);
  }

}