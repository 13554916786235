.link {

  @apply transition-all duration-100 ease-in-out;

  &-white {
    @apply text-white sm:hover:text-primary-100;
  }

  &-gray-70 {
    @apply text-grey-0.7 sm:hover:text-gd-grey;
  }

  &-primary-400 {
    @apply text-primary-400 sm:hover:text-primary-600;
  }

  &-legacy-purple {
    @apply text-legacy-purple sm:hover:text-primary-900;
  }

  &-primary-900 {
    @apply text-primary-900 sm:hover:text-legacy-purple;
  }

}