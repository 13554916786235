.container {

  .content {
    padding-bottom: 120px;
  }

  @screen sm {

    .one-third {
      @apply flex-shrink-0;
      width: calc(33.3333% - 16px);
    }

    .two-thirds {
      @apply flex-1 flex-shrink-0;
      width: calc(66.6667% - 16px);
    }

  }

}