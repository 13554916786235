.container {

  .select {
    @apply border-primary-200 text-primary-900;
    &-sep {
      @apply bg-clear;
    }
    &-focused {
      @apply border-legacy-purple hover:border-primary-400 outline-primary-400 shadow-none;
    }
    &-option {
      @apply text-primary-200 uppercase font-medium;
      font-weight: 500 !important;
      &-focused {
        @apply text-legacy-purple bg-primary-50 active:bg-primary-100;
      }
      &-selected {
        @apply bg-primary-500 text-white;
      }
    }
  }

}