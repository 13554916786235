.container {

  .content {

    @apply pb-6;

    .space {

      @apply aspect-square flex-shrink-0;
      width: 16px;

      @screen sm {
        width: 8.2883%;
      }

    }
    .detail {
      ul {
        padding-left: 40px;
        list-style-type: disc;
      }
    
      a {
        color: rgb(89 79 116 / var(--tw-text-opacity));
        font-weight: 400;
        text-decoration: underline;
      }
    }

  }

}