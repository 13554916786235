.container {

  hr {
    @apply w-full sm:w-[100px] h-px border-0;
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

}

.footer {

}