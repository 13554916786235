.container {

  padding: 216px 0 16px 0;



  .mobile {

    aspect-ratio: 1.3333;
    transform: translate(20%, -50%);
    width: 700px;

    @screen sm {
      width: 108.3636%;
      max-height: 894px;
      transform: translate(40%, -44%);
    }
  }

  h2 {
    line-height: 1.1;
  }

  p {
    line-height: 1.22;
  }

  a {
    span {
      line-height: 1.2;
    }
  }

}