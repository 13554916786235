

$content-side-padding: 16px;
$content-paddings: 2 * $content-side-padding;

section, header, footer {
  @apply overflow-hidden;
}

section, header, nav, footer, dialog, .dialog {

  @responsive {

    .content {
      @apply relative mx-auto w-full box-border;
      padding: 0 $content-side-padding;
      max-width: 1100px + 2 * $content-paddings;

      &.condensed {
        max-width: 752px + 2 * $content-paddings;
      }
    }

  }

}

.section-left, .section-right {
  .content {
    @apply px-0;
  }
}
