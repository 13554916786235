.container {

  .minimum {
    background: linear-gradient(207deg, rgba(235, 234, 240, 0.10) 0%, rgba(117, 111, 142, 0.10) 100%);
  }

  .initial {
    background: linear-gradient(207deg, rgba(235, 234, 240, 0.10) 0%, rgba(117, 111, 142, 0.10) 100%);
  }

}