.container {

  hr {
    background: linear-gradient(207deg, #EBEAF0 0%, #756F8E 100%);
  }

}

.channels {

  hr {
    background: linear-gradient(207deg, #EBEAF0 0%, #756F8E 100%);
  }

  .item {
    @screen sm {
      max-width: calc(33.3333% - 21px);
    }
  }

}