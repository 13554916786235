.container {

  hr {
    @apply h-px;
    background: linear-gradient(207deg, #EBEAF0 0%, #756F8E 100%);
  }

  .title {
    background: linear-gradient(265deg, #FFF 0%, #EBEAF0 100%);
  }

  .slide {
    &::-webkit-scrollbar{
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }

}