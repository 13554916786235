.container {

  background-image: url(../../../../../../../../assets/images/page-home-section-goals/goal-background-default.png);

  @screen sm {
    &:hover {
      background-image: url(../../../../../../../../assets/images/page-home-section-goals/goal-background-active.png);
    }
  }


  hr {
    @apply block border-0 h-px w-full;
    background: linear-gradient(207deg, rgba(235, 234, 240, 0.00) 23.44%, #756F8E 100%);
  }

}