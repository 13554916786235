.container {

  //.background {
    @apply bg-center bg-no-repeat bg-cover;
    background-image: url(../../../../assets/images/page-why-us-digital/mobile-background.png);

    @screen sm {
      //background-image: url(../../../../assets/images/page-why-us-digital/background.svg);
      background-image: none;
    }
  //}

}