.container {

  background-size: 100% 100%;
  max-height: 480px;

  .title {
    line-height: 1.1;
  }

}

.description {
  padding: 84px 0;
}
