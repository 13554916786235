$primary-gradient: linear-gradient(220deg, #615B99 0%, #594F74 52.91%, #372E43 100%);
$light-gradient: linear-gradient(315deg, rgba(#756F8E, 0.5) 0%, rgba(#EBEAF0, 0.5) 100%);
$light-gradient-4: linear-gradient(265deg, #FFF 0%, #EBEAF0 100%);
$grey-gradient: linear-gradient(150deg, #EBEAF0 0%, #FFF 100%);

.bg {

  &-primary-gradient {
    background: $primary-gradient;
  }

  &-light-gradient {
    background: $light-gradient;
  }

  &-light-gradient-4 {
    background: $light-gradient-4;
  }

  &-grey-gradient {
    background: $grey-gradient;
  }

}