.container {

  padding: 72px 0 96px;

  .short {
    @screen sm {
      width: calc(33.3333% - 16px);
    }
  }

  .long {
    @screen sm {
      width: calc(66.6667% - 16px);
    }
  }

}