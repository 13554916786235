.container {

  &.light {
    background: linear-gradient(265deg, #EBEAF0 0%, #FFF 100%);
  }

  span {
    line-height: 1.22;
  }

}