.container {

  .title {
    height: 3.75rem;
  }

  .title-rule {
    @apply w-[200px] h-px;
    background: linear-gradient(207deg, #756F8E 0%, #EBEAF0 100%);
  }

  .goals {

    :global(.goal):nth-child(odd) {
      @screen sm {
        @apply mb-8;
      }
    }

    :global(.goal):nth-child(even) {
      @screen sm {
        @apply mt-8;
      }
    }

  }

}