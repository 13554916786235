.container {

}

.footer {

  hr {
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

  .divider {
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

}