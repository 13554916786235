.container {

  .dot {
    background: linear-gradient(220deg, #615B99 0%, #594F74 52.91%, #372E43 100%);
  }

  .line {
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

}