.container {


  @apply hidden pointer-events-none;
  * {
    @apply pointer-events-none;
  }

  &.visible {
    @apply flex pointer-events-auto;
    * {
      @apply pointer-events-auto;
    }
  }

  .head {
    background: linear-gradient(124deg, #EBEAF0 26.26%, #FFF 99.21%);
  }

  .steps {
    @apply px-4 py-4 opacity-0 transition-all duration-700 ease-in-out;
    @screen sm {
      @apply py-0 max-h-0;
    }

    &.visible {
      @apply opacity-100 py-4;
      max-height: 80px;
    }
  }

  .back {
    @apply px-4 pb-4 pt-0 opacity-0 transition-all duration-700 ease-in-out;
    @screen sm {
      @apply py-0 max-h-0;
    }

    &.visible {
      @apply opacity-100 px-4 pb-4 pt-0;
      max-height: 80px;
    }
  }

  .steps {

    li {
      @apply relative box-border rounded-xl border border-clear transition-all duration-300 ease-in-out w-6 h-6 text-primary-400 flex flex-row justify-center items-center text-base font-normal;


      &.active {
        @apply border border-primary-400;
        background: linear-gradient(124deg, #EBEAF0 26.26%, #FFF 99.21%);
      }

      &::before {
        @apply absolute -left-[19px] top-1/2 w-4 h-px bg-primary-400 transform -translate-y-1/2;
        content: "";
      }
    }

    li:first-child {
      &::before {
        display: none;
      }
    }
  }

  .content {
    @apply bg-cover bg-center bg-no-repeat transition-all duration-700 ease-in-out;
    background-image: url(../../../../assets/images/dialog-contact/background.png);

    &.bg-blank {
      background-image: url(../../../../assets/images/dialog-contact/blank.png);
    }
  }

  hr {
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

  :global(.swiper-wrapper) {
    @screen sm {
      height: 100% !important;
    }
  }

  :global(.swiper-slide) {
    @screen sm {
      height: 100% !important;
    }
  }

}