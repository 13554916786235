@mixin text-h1 {
  @apply font-serif font-bold;
  font-size: 2rem;
  line-height: 1.1;
}

@mixin text-h2 {
  @apply font-serif font-bold;
  font-size: 1.5rem;
  line-height: 1.1;
}

@mixin text-h3 {
  @apply font-serif font-bold;
  font-size: 1.5rem;
  line-height: 1.2;
}

@mixin text-h4 {
  @apply font-normal text-xl;
  line-height: 1.2;
}

@mixin text-sm-h1 {
  font-size: 3.375rem;
}

@mixin text-sm-h2 {
  font-size: 2.375rem;
}

@mixin text-sm-h3 {
  font-size: 2rem;
}

@responsive {
  .text-h1 {
    @include text-h1;
  }

  .text-h2 {
    @include text-h2;
  }

  .text-h3 {
    @include text-h3;
  }

  .text-h4 {
    @include text-h4;
  }

  .text-sm-h1 {
    @include text-sm-h1;
  }

  .text-sm-h2 {
    @include text-sm-h2;
  }

  .text-sm-h3 {
    @include text-sm-h3;
  }
}

h1 {
  @include text-h1;
}

h2 {
  @include text-h2;
}

h3 {
  @include text-h3;
}

h4 {
  @include text-h4;
}

@screen sm {

  h1 {
    @include text-sm-h1;
  }

  h2 {
    @include text-sm-h2;
  }

  h3 {
    @include text-sm-h3;
  }

}