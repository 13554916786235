@font-face {
    font-family: 'Kanit';
    src: url('Kanit-LightItalic.woff2') format('woff2'),
        url('Kanit-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Italic.woff2') format('woff2'),
        url('Kanit-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Medium.woff2') format('woff2'),
        url('Kanit-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Light.woff2') format('woff2'),
        url('Kanit-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Thin.woff2') format('woff2'),
        url('Kanit-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-MediumItalic.woff2') format('woff2'),
        url('Kanit-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-ThinItalic.woff2') format('woff2'),
        url('Kanit-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Black.woff2') format('woff2'),
        url('Kanit-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-SemiBold.woff2') format('woff2'),
        url('Kanit-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-SemiBoldItalic.woff2') format('woff2'),
        url('Kanit-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-BlackItalic.woff2') format('woff2'),
        url('Kanit-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Regular.woff2') format('woff2'),
        url('Kanit-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-BoldItalic.woff2') format('woff2'),
        url('Kanit-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-ExtraLightItalic.woff2') format('woff2'),
        url('Kanit-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-ExtraBoldItalic.woff2') format('woff2'),
        url('Kanit-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-Bold.woff2') format('woff2'),
        url('Kanit-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-ExtraLight.woff2') format('woff2'),
        url('Kanit-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kanit';
    src: url('Kanit-ExtraBold.woff2') format('woff2'),
        url('Kanit-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

