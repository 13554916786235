.container {

  @apply bg-cover bg-top bg-no-repeat;
  padding: 96px 0 144px;
  background-image: url(../../../../../../assets/images/page-why-us-expertise-section-experts/background.svg);

  hr {
    @apply border-0 h-px;
    background-image: linear-gradient(207deg, #756F8E 0%, #EBEAF0 100%);
  }

}