input {

  .input,
  &[type=text],
  &[type=password],
  &[type=number],
  &[type=tel],
  &[type=email] {

    @apply box-border m-0 p-4 leading-5 rounded-lg border border-gd-grey-light outline-0 font-light;

    &::placeholder {
      @apply text-gd-grey-light font-light;
    }

    &:focus {
      @apply border-primary-400 text-primary-900;
    }

    &:disabled {
      @apply border-primary-200 text-primary-200 bg-primary-50;
    }

    &.error {
      @apply border-error text-error;
    }

  }

}

.btn {

  @apply p-4 box-border flex flex-row justify-center items-center rounded-lg outline-none font-medium text-base leading-[1.2] border transition-all duration-100 ease-in-out uppercase;
  line-height: 1.2;

  @responsive {

    &.btn-rounded-left {
      @apply rounded-r-none;
    }

    &.btn-rounded-right {
      @apply rounded-l-none;
    }

    &.btn-rounded-sm {
      @apply rounded;
    }

    &.btn-p-0 {
      @apply p-0;
    }

    &.btn-sm {
      @apply rounded py-1 px-2 text-xs;
    }

    &.btn-compact {
      @apply p-3;
    }

    &.btn-compact-long {
      @apply px-4 py-3;
    }

    &.btn-md {
      @apply px-4 py-2;
    }

    &.btn-long {
      @apply px-6 py-2;
    }

    &.btn-lg {
      @apply p-4;
    }

    &.btn-lg-short {
      @apply px-2 py-4;
    }

    &.btn-xl {
      @apply p-6;
    }

  }

  * {
    @apply transition-all duration-100 ease-in-out;
  }

  &-bright {
    @apply border-clear bg-white text-primary-900 font-semibold;

    @screen sm {
      &:hover:not([disabled]) {
        @apply text-black;
        background: #fbfbfb;
      }
    }

    &-light {
      @apply text-legacy-purple;
      line-height: 1.2;

      @screen sm {
        &:hover:not([disabled]) {
          @apply text-primary-900;
          background: #fbfbfb;
        }
      }
    }
  }

  &-outlined {
    @apply text-primary-900 border-primary-200;
    background-image: url(../images/common/blank.png);

    @screen sm {
      &:hover:not([disabled]) {
        @apply text-black border-primary-400;
      }
    }

    &-dark {
      @apply border-primary-900 text-primary-900;
      @screen sm {
        &:hover:not([disabled]) {
          @apply text-black border-black;
        }
      }
    }

    &-light {
      @apply border-primary-100 text-legacy-purple;
      @screen sm {
        &:hover:not([disabled]) {
          @apply text-primary-900 border-primary-200;
        }
      }
    }

    &-superlight {
      @apply border-primary-100 text-white;
      @screen sm {
        &:hover:not([disabled]) {
          @apply border-white text-white;
        }
      }
    }

    &-contrast {
      @apply border-primary-100 text-primary-900;
      @screen sm {
        &:hover:not([disabled]) {
          @apply text-black border-primary-200;
        }
      }
    }

  }

  &-submit {

    @apply uppercase border-clear font-medium text-white;
    background-image: linear-gradient(255deg, #615B99 2.24%, #594F74 60.08%, #372E43 111.56%);

    @screen sm {
      &:hover {
        background-image: linear-gradient(255deg, #60567d 2.24%, #504469 60.08%, #271E33 111.56%);
      }
    }

    &:disabled {
      @apply bg-primary-50 text-primary-100;
      background-image: url(../images/common/blank.png);

      @screen sm {
        &:hover {
          @apply filter-none;
        }
      }
    }

  }

  &-next {
    *:last-child {
      @apply relative mr-8;
      &::after {
        @apply absolute w-6 h-6 top-1/2 -right-8 transform -translate-y-1/2 bg-no-repeat bg-contain bg-right font-icon text-2xl flex flex-row justify-center items-center;
        content: '\e805'
      }
    }
  }

  &-more {
    *:last-child {
      @apply relative mr-8;
      &::after {
        @apply absolute w-6 h-6 top-1/2 -right-8 transform -translate-y-1/2 bg-no-repeat bg-contain bg-right font-icon text-2xl flex flex-row justify-center items-center;
        content: '\e807'
      }
    }
  }

}