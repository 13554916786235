.container {

  .logo {
    height: 24px;
  }

  .menu {
    li {
      & + li {
        margin-left: 120px;
      }
    }
  }

}