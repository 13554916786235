.container {

  ul {
    list-style-type: square;
    
    li{
      margin-bottom: 15px;
    }
  }

}