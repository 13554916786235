.container {

  padding-bottom: 120px;

  .macbook {

    @apply relative left-1/2;
    width: 200%;
    aspect-ratio: 1.3328;
    transform: translate(-50%, 0);

    @screen sm {
      @apply relative left-0 w-full;
      aspect-ratio: 1.7480;
      max-height: 635px;
      transform: none;
    }

  }

  .tablet {
    @apply relative left-1/2;
    width: 200%;
    aspect-ratio: 1.3328;
    transform: translate(-50%, 0);

    @screen sm {
      @apply absolute left-0 w-auto;
      width: 69.9091%;
      transform: translate(-18%, 42%);
    }

  }

  hr {
    @apply border-0 h-px;
    background: linear-gradient(207deg, #756F8E 0%, #EBEAF0 100%);
  }

  h2 {
    line-height: 1.1;
  }

  p {
    line-height: 1.22;
  }

  a {
    span {
      line-height: 1.2;
    }
  }

}