.container {

  input[type=text],
  input[type=email],
  input[type=tel],
  textarea {
    @apply p-4 border border-primary-200 rounded-lg outline-none leading-[1.22] resize-none;

    &:focus, &:not(:placeholder-shown) {
      @apply border-primary-400;
    }
  }

  hr {
    @apply w-full sm:w-[100px] h-px border-0;
    background: linear-gradient(180deg, #756F8E 0%, #ABAABD 100%);
  }

  .select {
    @apply border-primary-200;
    &-sep {
      @apply bg-clear;
    }
    &-focused {
      @apply border-legacy-purple hover:border-primary-400 outline-primary-400 shadow-none;
    }
    &-option {
      @apply text-primary-400;
      &-focused {
        @apply text-legacy-purple bg-primary-50 active:bg-primary-100;
      }
      &-selected {
        @apply bg-primary-500 text-white;
      }
    }
  }

}

.footer {

}