.container {

  .content {
    min-height: 492px;
  }

  .mobile {

    width: 620px;
    aspect-ratio: 1.3329;
    transform: translate(-50%, -120px);

    @screen sm {
      width: 94.5045%;
      max-height: 787px;
      transform: translate(-15%, -50%);
    }

  }

  h2 {
    line-height: 1.1;
  }

  p {
    line-height: 1.22;
  }

  a {
    span {
      line-height: 1.2;
    }
  }

}